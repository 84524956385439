// import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';

function App() {
  return (
    <div>
      <Home />
      {/* <About /> */}
    </div>
  );
}

export default App;
